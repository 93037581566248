.trackingIdSections {
  background-color: #f9f9f9; /* Light grey background */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Spacing inside the container */
  margin-top: -10px; /* Spacing above the section */
  display: inline-block; /* Ensure the container fits content */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin-bottom: 20px;
}

.trackingIdSections h2 {
  font-size: 1.25rem; /* Medium font size */
  color: #333; /* Dark grey text */
  font-weight: 700; /* Slightly bold for emphasis */
  margin: 0; /* Remove margin to keep in one line */
  display: inline-block; /* Keep the heading in the same line */
}

.trackingIdSections h2 span {
  font-size: 1.25rem; /* Same size for the tracking ID */
  color: #007bff; /* Blue color for the tracking ID */
  font-weight: bold; /* Make the tracking ID bold */
}

.orderItemSizes {
  margin-top: 2px; /* Adjusted margin for spacing */
  padding: 3px 2px; /* Slight padding for a compact look */
  background-color: #f5f5f5; /* Light gray background */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Consistent font size */
  font-weight: 500; /* Medium weight for emphasis */
  color: #333; /* Dark gray text */
  display: inline-block;
}

.orderItemSizes span {
  color: #007bff; /* Primary blue color */
  font-weight: bold; /* Bold text for size */
}

.orderDetailsPage {
  background-color: white;
}

.orderDetailsContainer > h1 {
  font: 300 2.5vmax "Playfair Display", serif;
  margin: 4vmax 0;
  color: #222222;
}

.orderDetailsContainer {
  padding: 5vmax;
  padding-bottom: 0%;
}

.orderDetailsContainer > p {
  font: 400 1.8vmax "Playfair Display", serif;
}

.orderDetailsContainerBox,
.orderDetailsCartItemsContainer {
  margin: 2vmax;
}

.orderDetailsContainerBox > div {
  display: flex;
  margin: 1vmax 0;
}

.orderDetailsContainerBox > div > p {
  font: 400 1vmax "Roboto";
  color: black;
}

.orderDetailsContainerBox > div > span {
  margin: 0 1vmax;
  font: 100 1vmax "Roboto";
  color: #575757;
}

.orderDetailsCartItems h2{
  font: 600 1.6vmax "Playfair Display", serif;
}

.orderDetailsCartItems > p {
  font: 400 1.8vmax "Roboto";
}

.orderDetailsCartItems {
  padding: 2vmax 5vmax;
  border-top: 1px solid rgba(0, 0, 0, 0.164);
}

/* Additional Styling Adjustments */
.orderDetailsCartItemsContainer > div {
  display: flex;
  flex-direction: column; /* Stacks elements vertically */
  align-items: flex-start; /* Aligns items to the start */
  margin: 2vmax 0;
}

.orderDetailsCartItemsContainer > div > img {
  width: 3vmax;
  margin-bottom: 8px; /* Adds space between the image and text */
}

.orderDetailsCartItemsContainer > div > a {
  color: #575757;
  margin: 0 0 8px 0; /* Adjusted margin for consistent spacing */
  text-decoration: none;
}

.orderDetailsCartItemsContainer > div > span {
  font: 100 1vmax "Roboto";
  color: #5e5e5e;
  margin-top: 4px; /* Space between size and amount */
}

/* Styles for the cancel order button */
.cancelOrderButton {
  margin-left: auto;
  padding: 10px 20px;
  background-color: tomato;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancelOrderButton:hover {
  background-color: lightcoral;
}

/* Styles for the confirmation dialog */
.confirmationDialog {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.confirmationDialog p {
  font-size: large;
}

.confirmationDialog button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.confirmationDialog button.yes {
  background-color: green;
  color: white;
}

.confirmationDialog button.no {
  background-color: red;
  color: white;
}

.confirmationDialog button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 600px) {

  .trackingIdSections h2 {
    font-size: 1rem; /* Medium font size */
    color: #333; /* Dark grey text */
    font-weight: 700; /* Slightly bold for emphasis */
    margin: 0; /* Remove margin to keep in one line */
    display: inline-block; /* Keep the heading in the same line */
  }
  
  .trackingIdSections h2 span {
    font-size: 1.10rem; /* Same size for the tracking ID */
    color: #007bff; /* Blue color for the tracking ID */
    font-weight: bold; /* Make the tracking ID bold */
  }

  .orderDetailsContainer > p {
    font: 400 6vw "Playfair Display", serif;
  }

  .orderDetailsCartItems h2{
    font: 600 2vmax "Playfair Display", serif;
  }

  .orderDetailsContainerBox > div {
    margin: 6vw 0;
  }

  .orderDetailsContainerBox > div > p {
    font: 400 4vw "Roboto";
  }
  .orderDetailsContainerBox > div > span {
    font: 100 4vw "Roboto";
  }

  .orderDetailsCartItems > p {
    font: 400 6vw "Roboto";
  }

  .orderDetailsCartItemsContainer > div {
    font: 400 4vw "Roboto";
    margin: 4vw 0;
  }

  .orderDetailsCartItemsContainer > div > img {
    width: 10vw;
  }

  .orderDetailsCartItemsContainer > div > a {
    margin: 2vw;
    width: 30%;
  }

  .orderDetailsCartItemsContainer > div > span {
    font: 100 4vw "Roboto";
  }

  .cancelOrderButton {
    width: 50%;
    margin: 50px 0 0 -110px; /* Adjusted margin to move the button left and down */
    padding: 8px;
    text-align: center;
  }
}
