.profileContainer {
  display: flex;
  height: 110vh;
  width: 100vw;
  position: fixed;
  top: 0%;
  left: 0%;
  max-width: 100%;
  background-color: white;
}

.profileContainer > div {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileContainer > div:first-child > h2 {
  color: rgba(0, 0, 0, 0.731);
  font: 500 2.4vmax "playfair display";
  transform: translateX(-10vmax) translateY(-2vmax);
  margin-left: 275px;
  margin-top: 30px;
  margin-bottom: -10px;
}

.profileContainer > div:first-child > img {
  width: 20vmax;
  border-radius: 100%;
  transition: all 0.5s;
}

.profileContainer > div:first-child > img:hover {
  transform: scale(1.05);
}

.profileContainer > div:first-child > a {
  border: none;
  background-color: #222222;
  font: 400 1vmax "playfair display";
  color: white;
  text-decoration: none;
  padding: 0.5vmax;
  width: 40%;
  margin: 2vmax;
  text-align: center;
  transition: all 0.5s;
}

.profileContainer > div:first-child > a:hover {
  background-color: rgb(161, 161, 225);
}

.profileContainer > div:last-child {
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 5vmax;
  box-sizing: border-box;
}

.profileContainer > div:last-child > div > h4 {
  color: black;
  font: 400 1.2vmax "playfair display";
}

.profileContainer > div:last-child > div > p {
  color: #222222;
  font: 400 1vmax "playfair display";
  margin: 0.2vmax;
}

.profileContainer > div:last-child > div:last-child {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.profileContainer > div:last-child > div:last-child > a {
  border: none;
  background-color: #222222;
  font: 400 1vmax "playfair display";
  color: white;
  text-decoration: none;
  padding: 0.5vmax;
  text-align: center;
  transition: all 0.5s;
  margin: 1vmax 0;
}

.profileContainer > div:last-child > div:last-child > a:hover {
  background-color: rgb(161, 161, 225);
}

@media screen and (max-width: 768px) {

  .profileContainer > div:first-child > h2 {
    color: rgba(0, 0, 0, 0.76);
    font: 500 2.5vmax "playfair display",serif;   
    margin-left: 157px;
    align-items: center;
    justify-content: center;   
  }

  .profileContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 3vmax;
    width: 100vw;
    height: 200vh;
    position: fixed;
    top: -20%;;
    left: 0;
    max-width: 100%;
  }

  .profileContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .profileContainer > div:first-child > img {
    width: 20vmax;
    border-radius: 100%;
    transition: all 0.5s;
    margin-bottom: 2vmax;
  }

  .profileContainer > div:first-child > img:hover {
    transform: scale(1.05);
  }

  .profileContainer > div:first-child > a {
    border: none;
    background-color: #222222; /* Brighter tomato color for better contrast */
    font: 400 1.7vmax "playfair display",serif;
    color: white;
    text-decoration: none;
    padding: 1vmax;
    width: 70%;
    text-align: center;
    transition: all 0.5s;
    margin-top: 2vmax;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  }

  .profileContainer > div:first-child > a:hover {
    background-color: rgb(133, 109, 109); /* Slightly darker on hover */
  }

  .profileContainer > div:last-child {
    text-align: center;
    align-items: center;
    padding: 2vmax;
    box-sizing: border-box;
  }

  .profileContainer > div:last-child > div {
    margin-bottom: 3vmax; /* Adds gap between each section */
  }
  
  .profileContainer > div:last-child > div > h4 {
    font: 400 2.5vmax "playfair display",serif;
    color: rgba(0, 0, 0, 1); /* Darker font for name, email, etc. */
    margin-bottom: 0.5vmax; /* Reduced space below headings */
  }
  
  .profileContainer > div:last-child > div > p {
    font: 400 2vmax "playfair display",serif;
    color: #222222; /* Darker font for details */
    margin: 0.5vmax 0;
  }
  

  .profileContainer > div:last-child > div:last-child {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .profileContainer > div:last-child > div:last-child > a {
    border: none;
    background-color: #222222; /* Attractive background color for buttons */
    font: 400 1.8vmax "playfair display",serif;
    color: white;
    text-decoration: none;
    padding: 1vmax;
    text-align: center;
    transition: all 0.5s;
    margin: 2vmax 0;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  }

  .profileContainer > div:last-child > div:last-child > a:hover {
    background-color: rgb(133, 109, 109); /* Slightly darker on hover */
  }
}
