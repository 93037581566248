.latest-collection-heading  {
    margin-bottom: 50px;
    margin-top: 60px;
    padding-top: 0px;
    text-align: center;
    color: #000;
    font-weight: 500px;
    line-height: 30px;
    font-size: 30px;
    font-family: "Playfair Display", serif;
}

.latest-collection-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 50px;
}

.latest-slide-show {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 25%;
    transform: translateX(0);
}

.latest-slide-show.sliding {
    transition: transform 0.5s ease-in-out;
}

.latest-slide {
    flex: 0 0 25%;
    text-align: center;
    padding: 0 35px;
    box-sizing: border-box;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.latest-slide:hover {
    opacity: 1;
}

.latest-slide img {
    cursor: pointer;
    border-radius: 54%;
    width: 108%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
    margin-top: 5%;
}

.latest-slide:hover img {
    transform: scale(1.1);
}

.latest-slide-name {
    font-family: "Playfair Display", serif;
    margin-top: 30px;
    font-size: 16px;
    color: #000;
    letter-spacing: 0.5px;
}

/* Navigation buttons */
.latest-slide-nav {
    background-color: #ffffff;
    border: none;
    color: #222222;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 10;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.latest-slide-nav.prev {
    left: 10px;
}

.latest-slide-nav.next {
    right: 10px;
}

.latest-slide-nav:hover {
    background-color: #707070;
}

.latest-slide-nav:disabled {
    opacity: 0.5;
}

/* Media query for mobile devices */
@media (max-width: 768px) {

    .latest-collection-heading  {
       font-size:20px;
       font-family: "Playfair Display", serif;
       margin-top: 50px;
       margin-bottom: 35px;
    }

    .latest-slide {
        flex: 0 0 50%; /* Show two images at a time */
        padding: 6px ; /* Adjust padding for mobile view */
    }

    .latest-slide-show {
        width: 50%; /* Adjust the width for two images */
    }

    .latest-slide img {
        width: 100%; /* Adjust image size for mobile view */
        margin-top: -5px;
    }

    .latest-slide-nav {
        font-size: small;
    }

    .latest-slide-nav.prev {
        left: 2px;
    }
    
    .latest-slide-nav.next {
        right: 2px;
    }
}