.policy-page {
    padding: 20px;
    max-width: 860px;
    margin: auto;
    margin-bottom: 30px;
  }
  
  .policy-page h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: "Playfair Display", serif;
    text-decoration: underline;
  }
  
  .policy-page p {
    font-family: "Playfair Display", serif;
    line-height: 1.8;
    margin-bottom: 15px;
    margin-top: 40px;
  }
  
@media screen and (max-width: 600px) {
  
  .policy-page h2 {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 20px;
    font-family: "Playfair Display", serif;
    text-decoration: underline;
  }
}