.emptyCart {
  margin: auto;
  margin-top: -50px;
  text-align: center;
  padding: 10vmax;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyCart > svg {
  font-size: 5vmax;
  color: #222222;
}

.emptyCart > p {
  font-size: 2vmax;
}

.emptyCart > a {
  background-color: rgb(161, 161, 225);
  color: white;
  border: none;
  padding: 1vmax 3vmax;
  cursor: pointer;
  font: 400 1.1vmax "Roboto";
  text-decoration: none;
}

.emptyCart > a:hover {
  background-color: rgb(102, 101, 101);
  transform: scale(1.1);
}

.cartPage {
  padding: 5vmax;
  margin-top: -50px;
}

.cartHeader {
  background-color: #222222;
  width: 90%;
  box-sizing: border-box;
  margin: auto;
  color: white;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  font: 300 0.9vmax "Playfair Display", serif;
}

.cartHeader > p {
  margin: 10px;
}

.cartHeader > p:last-child {
  text-align: end;
}

.cartContainer {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
}

.cartInput {
  display: flex;
  align-items: center;
  height: 8vmax;
}

.cartInput > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.6vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}

.cartInput > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.cartInput > input {
  border: none;
  padding: 0.5vmax;
  width: 2.9vmax;
  text-align: center;
  outline: none;
  font: 400 0.9vmax "Roboto";
  color: rgba(0, 0, 0, 0.74);
}

.cartSubtotal {
  display: flex;
  padding: 0.5vmax;
  height: 8vmax;
  align-items: center;
  box-sizing: border-box;
  font: 300 1.1vmax "Playfair Display", serif;
  justify-content: flex-end;
  color: #222222;
}

.cartGrossProfit {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
}

.cartGrossProfitBox {
  border-top: 3px solid #222222;
  margin: 1vmax 4vmax;
  box-sizing: border-box;
  padding: 2vmax 0;
  font: 300 1.1vmax "Playfair Display", serif;
  display: flex;
  justify-content: space-between;
}

.checkOutBtn {
  display: flex;
  justify-content: flex-end;
}

.checkOutBtn > button {
  background-color: #222222;
  color: white;
  border: none;
  padding: 0.8vmax 4vmax;
  width: 50%;
  font: 300 0.8vmax "Roboto";
  margin: 1vmax 4vmax;
  cursor: pointer;
  border-radius: 30px;
}

.checkOutBtn  > button:hover {
  background-color: rgb(105, 100, 99);
  transform: scale(1.1);
}

@media screen and (max-width: 600px) {
  .cartPage {
    padding: 0;
    min-height: 80vh;
    margin-top: 40px;
  }

  .cartHeader {
    width: 100%;
    font: 300 1.6vmax "Playfair Display", serif;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartContainer {
    width: 100%;
    grid-template-columns: 3fr 1fr 1fr;
  }

  .cartInput {
    height: 20vmax;
    margin-right: 15px;
  }

  .cartInput > button {
    padding: 1.2vmax;
  }

  .cartInput > input {
    width: 7vmax;
    padding: 1.5vmax;
    font: 400 1.8vmax "Roboto";
  }

  .cartSubtotal {
    height: 20vmax;
    font: 300 1.9vmax "Playfair Display", serif;
  }

  .cartGrossProfit {
    display: grid;
    grid-template-columns: 0fr 2fr;
  }

  .cartGrossProfitBox {
    padding: 2vmax;
    font: 300 2vmax "Playfair Display", serif;
  }

  .checkOutBtn > button {
    padding: 2vmax 4vmax;
    width: 100%;
    font: 300 2vmax "Playfair Display", serif;
  }

  .emptyCart {
    padding: 4vmax;
    margin-top: -70px;
  }

  .emptyCart > svg {
    font-size: 6vmax;
  }

  .emptyCart > p {
    font-size: 2.5vmax;
  }

  .emptyCart > a {
    padding: 2vmax 4vmax;
    font-size: 1.7vmax;
  }
}
