.related-products-section {
  padding: 20px;
  text-align: center; /* Center text */
}

.relatedheading {
  font-size: 24px;
  margin-bottom: 20px;
}

.no-related-products {
  margin: 20px 0; /* Space around the message */
  padding: 10px; /* Padding for the message */
  background-color: #f9f9f9; /* Light background color */
  border: 1px solid #ddd; /* Border around the message */
  border-radius: 5px; /* Rounded corners */
  color: #555; /* Text color */
  font-size: 18px; /* Font size */
  font-weight: 500; /* Bold text */
}

.cart-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 800px; /* Slightly smaller max-width */
  padding: 1px; /* Reduced padding for a compact look */
  padding-left: 5px;
  /* background-color: #f4f4f4; */
  border-radius: 8px; /* Slightly smaller border-radius */
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1); /* Slightly smaller shadow */
  margin: 1px 1px; /* Less margin to take up less space */
  animation: slide-in 0.4s ease-out; /* Slightly faster animation */
}

.cart-notification span {
  white-space: nowrap;
  font-size: 14px; /* Smaller font size */
  color: #fff;
  flex: 1;
}

.view-cart-button {
  padding: 4px 15px; /* Smaller button padding */
  font-size: 12px; /* Smaller font size */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px; /* Reduced border-radius */
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease; /* Slightly faster transitions */
  margin-left: 20px; /* Reduced space between text and button */
}

.view-cart-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Smaller hover movement */
}

.view-cart-button:active {
  transform: translateY(0);
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slightly reduced initial movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.related-products-section {
  padding: 8px; /* Padding for the section */
  border-radius: 8px; /* Rounded corners */
}

.related-products {
  display: flex; /* Use Flexbox for layout */
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
  justify-content: space-around; /* Space out items evenly */
  flex: 1 1 calc(20% - 20px); /* 4 items per row with spacing */
  margin: 5px; /* Spacing between items */
  min-width: 200px; /* Minimum width for each card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
  border-radius: 4px; /* Rounded corners for cards */
  background-color: #fff; /* White background for product cards */
  transition: transform 0.2s; /* Smooth scaling on hover */
  margin-bottom: 30px;
}

.related-products :hover {
  transform: scale(1.04); /* Slight zoom effect on hover */
}

.relatedheading {
  color: #000000be;
  font: 600 1.5vmax "Roboto", sans-serif;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 22vmax;
  margin: auto;
  margin-bottom: 4vmax;
  margin-top: 30px;
}

.meterssText{
  font-weight: bold;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.651);;
}

.stockInfo {
  font-size: 18px; /* Adjust the font size */
  font-weight: bold; /* Make it bold */
  color: #333; /* Set text color */
  margin-top: 10px; /* Add space above */
}

.metersText {
  font-weight: 300;
  color: #333; /* Set a different color for the "meters" text */
  font-style: italic; /* Italicize the "meters" text */
}

/* Container for size selection */
.detailsBlock-3 {
  margin-top: 10px;
}

/* Header for size selection */
.detailsBlock-3 h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

/* Container for size options */
.sizeOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Style for each size button */
.sizeButton {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  outline: none;
}

/* Style for the active size button */
.sizeButton.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

/* Hover effect for size buttons */
.sizeButton:hover {
  background-color: #4caf50;
  border-color: #bbb;
}

/* Focus effect for size buttons */
.sizeButton:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(38, 143, 255, 0.3);
}

.additionalInfo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
  gap: 17px;
}

.infoItem {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  padding: 0 10px;
}

.infoItem {
  font-family: "Playfair Display", serif;
}

.infoItem::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: 1px;
  background-color: #ddd;
}

.infoItem:last-child::after {
  display: none;
}

.infoItem svg {
  font-size: 24px;
  color: #7171ff; /* Adjust the color as needed */
}

.ProductDetails {
  background-color: rgb(255, 255, 255);
  width: 100vw;
  max-width: 100%;
  padding: 1vmax;
  box-sizing: border-box;
  display: flex;
}

.ProductDetails > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 4vmax;
  box-sizing: border-box;
  border: 1px solid white;
  margin-top: -15px;
}

.ProductDetails > div:last-child {
  align-items: flex-start;
}

.CarouselImage {
  width: 37vmax;
  margin-bottom: 20px;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out; /* Smooth transition for hover effects */
}

.CarouselImage:hover {
  transform: scale(1.1) rotate(1deg); /* Slight rotation and zoom effect */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add a soft shadow */
  cursor: pointer;
}

/* Container for the thumbnails */
.ThumbnailContainer {
  display: flex;
  justify-content: center;
  gap: 12px; /* Adjust gap between thumbnails */
  margin-top: 15px;
  animation: fadeIn 1s ease-in-out; /* Add a fade-in animation */
}

/* Wrapper for each thumbnail and its icon */
.ThumbnailWrapper {
  position: relative;
  width: 10vmax;
  height: auto;
  cursor: pointer;
}

/* Styling for the thumbnail images */
.ThumbnailImage {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  transition: border 0.3s ease, transform 0.3s ease;
  opacity: 0.8; /* Slight transparency for a sleek look */
}

/* On hover, add a border and slight zoom effect */
.ThumbnailWrapper:hover .ThumbnailImage {
  border: 0.3px solid #ABB427; /* Add a green border on hover */
  transform: scale(1.05); /* Increase zoom slightly */
  opacity: 1; /* Make thumbnail fully opaque */
}

/* Zoom icon container */
.ZoomIconWrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; /* White background */
  border-radius: 50%; /* Circle background */
  transition: opacity 0.3s ease;
  opacity: 0; /* Hidden by default */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /* Optional: Adds a slight shadow */
}

.ThumbnailWrapper:hover .ZoomIconWrapper {
  opacity: 1; /* Show on hover */
}

/* Styling for the zoom icon */
.ZoomIcon {
  font-size: 0.8rem;
  color: #555; /* Lighter gray color for a softer look */
  font-weight: bold; /* Ensures the icon remains bold */
}


/* Animation for the fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start from below */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Return to original position */
  }
}

.MuiSvgIcon-root.Carousel-indicatorIcon-17{
  font-size: 12px;
}

.MuiSvgIcon-root.Carousel-indicatorIcon-17 :hover{
  color: #4caf50;
}

.react-material-ui-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color */
  border-radius: 50%; /* Make the button circular */
  padding: 8px; /* Increase padding for better visibility */
  transition: background-color 0.3s ease; /* Add transition for smooth hover effect */
}

.react-material-ui-carousel__arrow:hover {
  background-color: rgba(0, 0, 0, 0.8); /* Darken the background color on hover */
}

.detailsBlock-1 > h2 {
  color: rgb(54, 54, 54);
  font: 600 1.6vmax "Roboto";
}

.detailsBlock-1 > p {
  color: rgba(54, 54, 54, 0.582);
  font: 200 0.9vmax "Playfair Display", serif;
}

.detailsBlock-2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.205);
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 70%;
  padding: 0.8vmax 0;
}

.detailsBlock-2-span {
  font: 200 1vmax "Playfair Display", serif;
  color: rgba(0, 0, 0, 0.699);
}

.detailsBlock-3 {
  width: 70%;
}

.detailsBlock-3 > h1 {
  color: rgba(17, 17, 17, 0.795);
  font: 400 1.6vmax "Franklin Gothic Medium";
  margin: 1vmax 0;
}
.detailsBlock-3-1 {
  display: flex;
  align-items: center;
}

.detailsBlock-3-1-1 > button {
  border: none;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 0.5vmax;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
}
.detailsBlock-3-1-1 > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}

.detailsBlock-3-1-1 > input {
  border: none;
  padding: 0.5vmax;
  width: 3vmax;
  text-align: center;
  outline: none;
  font: 400 1vmax "Roboto";
  color: rgba(0, 0, 0, 0.868);
}

.detailsBlock-3-1 > button:last-child {
  border: none;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  background-color: #222222;
  font: 500 0.8vmax "Roboto";
  border-radius: 20px;
  padding: 0.5vmax 2vmax;
  margin: 1vmax;
  outline: none;
}

.detailsBlock-3-1 > button:last-child:hover {
  background-color: rgb(102, 101, 101);
  transform: scale(1.1);
}

.detailsBlock-3 > p {
  border-top: 0.5px solid rgba(0, 0, 0, 0.205);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.205);
  padding: 1.2vmax 0;
  color: rgba(0, 0, 0, 0.651);
  font: 600 1.1vmax "Roboto";
  margin: 0.8vmax 0;
}

/* Container for the description block */
.detailsBlock-4 {
  background-color: #222222;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between elements */
  align-items: center; /* Center vertically */
  width: 90%; /* Adjust width as necessary */
  transition: background-color 0.3s ease; /* Smooth background transition */
  margin-top: 20px;
}

/* Hover effect for the description block */
.detailsBlock-4:hover {
  background-color: #333333; /* Slightly lighter background on hover */
}

/* Button styling */
.toggle-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 5px 13px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/* Hover effect for the button */
.toggle-button:hover {
  background-color: white; /* White background on hover */
  color: #222222; /* Dark text on white background */
}

/* Content styling */
.details-content {
  margin-top: 20px; /* Optional margin for spacing */
  color: rgba(0, 0, 0, 0.897);
  font: 550 1.2vmax sans-serif;
}

.details-content p {
  color: rgb(54, 54, 54);
  font: 500 1.2vmax sans-serif;
  margin-top: 10px;
}

/* Additional styling for the details of the Fabric category */
.detailsBlocks-3 {
  margin-top: 20px; /* Optional margin for spacing */
  color: rgba(0, 0, 0, 0.897);
  font: 550 1.2vmax sans-serif;
}

/* Optional h3 styling for better visibility */
.detailsBlocks-3 p {
  color: rgb(54, 54, 54);
  font: 500 1.2vmax sans-serif;
  margin-top: 10px;
}

.detailsBlock-4 span{
  margin-left: 10px;
}

.submitReview {
  border: none;
  background-color: #222222;
  font: 500 1vmax "Roboto";
  border-radius: 20px;
  padding: 0.6vmax 2vmax;
  margin: 2vmax 0;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
  margin-top: 30px;
}

.submitReview:hover {
  background-color: rgb(102, 101, 101);
  transform: scale(1.1);
}

.submitDialog {
  display: flex;
  flex-direction: column;
}

.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem "Roboto";
}

.reviewsHeading {
  color: #000000be;
  font: 600 1.5vmax "Roboto", sans-serif;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 25vmax;
  margin: auto;
  margin-bottom: 4vmax;
  margin-top: 30px;
  white-space: nowrap; /* Prevents wrapping */
}

.reviews {
  display: flex;
  overflow: auto;
}

.reviewCard {
  flex: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.226);
  border: 1px solid rgba(56, 56, 56, 0.116);
  width: 30vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1vmax;
  padding: 3vmax;
}

.reviewCard > img {
  width: 5vmax;
}

.reviewCard > p {
  color: rgba(0, 0, 0, 0.836);
  font: 600 0.9vmax "Playfair Display", serif;
}

.reviewCardComment {
  color: rgba(0, 0, 0, 0.445);
  font: 300 0.8vmax "Playfair Display", serif;
}

.noReviews {
  font: 400 1.3vmax "Gill Sans";
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

/* Main container for the share buttons */
.share-buttons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Increase gap for better spacing */
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Share text styling */
.share-text {
  font: 500 23px "Playfair Display", serif;
  color: rgb(94, 94, 94); /* Set text color */
  margin-left: 6px; /* Adds space between text and buttons */
  align-self: center; /* Vertically center the text */
}

/* Circle buttons */
.share-btn {
  width: 50px; /* Circle diameter */
  height: 50px;
  padding: 0;
  border-radius: 50%; /* Full circle */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent; /* No background by default */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  animation: button-appear 0.4s ease; /* Animate button appearance */
  border: 2px solid transparent; /* Ensure no black border by default */
}

/* Icon styling */
.share-btn i {
  font-size: 26px;
  color: white; /* White icons for a modern look */
  margin: 0;
}

/* Button hover effect */
.share-btn:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Soft shadow on hover */
}

/* Specific button colors for different platforms */
.whatsapp {
  background-color: #25D366;
  border-color: #25D366; /* Match border color with WhatsApp background */
}

.instagram {
  background: linear-gradient(45deg, #fdc468, #fa709a, #d62976, #962fbf, #4f5bd5);
  border: none; /* No border for Instagram */
  padding: 0; /* Ensure no extra padding for Instagram */
}

.facebook {
  background-color: #4267B2;
  border-color: #4267B2; /* Match border color with Facebook background */
}

.snapchat{
  background-color: #FFEB3B; /* Softer yellow */
  color: #000; /* Black text/icon for contrast */

}

/* Hover color changes for each button */
.whatsapp:hover {
  background-color: #20B45B;
  border-color: #20B45B; /* Update border color on hover */
}

.instagram:hover {
  background-color: #D81E5B; /* Slightly darker pink on hover */
  border-color: transparent; /* For gradient, keep border transparent */
}

.facebook:hover {
  background-color: #36589D; /* Darker Facebook blue on hover */
  border-color: #36589D; /* Update border color on hover */
}

/* Animation for button appearance */
@keyframes button-appear {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Ripple effect on hover */
.share-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.1); /* Light ripple color */
  transform: translate(-50%, -50%) rotate(45deg);
  transition: width 0.5s ease, height 0.5s ease;
  border-radius: 50%; /* Keep ripple circular */
}

.share-btn:hover::before {
  width: 150%;
  height: 150%;
}

.price-container {
  display: flex; /* Use flexbox for alignment */
  flex-direction: row; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  margin: 20px;
  margin-left: -5px;
  width: 150%;
}

.crossed-out-price {
  color: grey; /* Or any color you prefer */
  font-size: 20px; /* Adjust font size as needed */
  text-decoration: line-through;
  margin-right: 10px; /* Space between MRP and Sale Price */
}

.currency-symbol {
  margin-right: 2px; /* Adjust as needed for spacing between currency and number */
}

.sale-price {
  font-size: 24px; /* Adjust font size for Sale Price as needed */
}

.sony{
  margin-top: -22px;
}

.tax-info { /* New class for the tax information */
  font-size: 16px;
  color: #222;
  margin-top: 5px; /* Space above the text */
}

.discountpercentagess {
  font-weight: bold;
  color: #C84450; /* Adjust the color as needed */
  font-size: 1.2rem; /* Set a suitable font size */
  margin-left: 10px;
}

@media screen and (max-width: 768px) {

  .sony{
    margin-top: 10px;
  }

  .tax-info { /* New class for the tax information */
    font-size: 15px;
    color: #222;
    margin-top: 10px; /* Space above the text */
    margin-left: 60px;
  }

  .discountpercentagess {
    font-size: 4.5vw; /* Responsive font size for mobile */
    margin-top: 2px; /* Increase spacing on mobile */
    margin-left: 90px;
  }

  .price-container{
    display: flex; /* Use flexbox for alignment */
    width: 200%;
    margin-bottom: -6px;
    margin-top: 15px;
    margin-left: 8px;
  }

  .price-row {
    justify-content: flex-start; /* Keep MRP and Sale Price aligned to the left */
  }

  .crossed-out-price {
    font-size: 18px; /* Slightly smaller font size for mobile */
  }

  .sale-price {
    font-size: 20px; /* Slightly smaller Sale Price for mobile */
  }

  .price-container p {
    font-size: 14px;
  }

  /* Content styling */
.details-content {
  margin-top: 20px; /* Optional margin for spacing */
  color: rgba(0, 0, 0, 0.897);
  font: 550 1.7vmax sans-serif;
}

.details-content p {
  color: rgb(54, 54, 54);
  font: 500 1.6vmax sans-serif;
  margin-top: 10px;
}

/* Additional styling for the details of the Fabric category */
.detailsBlocks-3 {
  margin-top: 30px; /* Optional margin for spacing */
  color: rgba(0, 0, 0, 0.897);
  font: 600 1.7vmax sans-serif;
  margin-bottom: 30px; /* Optional margin for spacing */
}

/* Optional h3 styling for better visibility */
.detailsBlocks-3 p {
  color: rgb(54, 54, 54);
  font: 500 1.6vmax sans-serif;
  margin-top: 12px;
}

  /* Container for the description block */
.detailsBlock-4 {
  background-color: #222222;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space between elements */
  align-items: center; /* Center vertically */
  width: 100%; /* Adjust width as necessary */
  transition: background-color 0.3s ease; /* Smooth background transition */
  margin-top: 20px;
}

  .detailsBlock-4 span{
    margin-left: 10px;
    font-size: 14px;
  }


  .detailsBlock-3 {
    margin-top: 10px; /* Same margin for consistency */
  }

  .detailsBlock-3 h3 {
    font-size: 1.25rem; /* Slightly smaller font size */
    margin-left: 55px;
  }

  .sizeOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Maintain consistent spacing */
    justify-content: center; /* Center buttons */
  }

  .sizeButton {
    padding: 10px 15px; /* Maintain same padding */
    font-size: 1rem; /* Same font size */
    max-width: 100px; /* Optional: restrict button width for better fit */
  }

  .additionalInfo {
    flex-direction: row;
    gap: 16px;
    padding-top: 20px;
    height: 120px;
  }

  .infoItem {
    width: 100%;
    justify-content: center;
    padding: 4px;
    text-align: center;
  }

  .infoItem::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
  width: 1.3px;
  background-color: #ddd;
  }

  .infoItem span {
    display: inline-block;
  }

  .infoItem:nth-child(1) span {
    white-space: nowrap; /* Prevent line break for "Free delivery over 1500+" */
  }

  .infoItem:nth-child(3) span {
    white-space: nowrap; /* Prevent line break for "7 days return" */
  }

  .ProductDetails {
    flex-direction: column;
    height: unset;
  }

  .ProductDetails > div:last-child {
    align-items: center;
  }

  .CarouselImage {
    width: 50vmax;
  }

  .detailsBlock-1 > h2 {
    font-size: 2.8vmax;
    text-align: center;
  }

  .detailsBlock-1 > p {
    text-align: center;
    font-size: 1.1vmax;
  }

  .detailsBlock-2 {
    justify-content: center;
  }

  .detailsBlock-2 > span {
    font-size: 1.5vmax;
  }

  .detailsBlock-3 > h1 {
    font: 700 3vmax "Franklin Gothic Medium";
    text-align: center;
  }

  .detailsBlock-3-1 {
    flex-direction: column;
  }

  .detailsBlock-3-1-1 {
    padding: 2vmax 0;
  }
  .detailsBlock-3-1-1 > button {
    padding: 1.2vmax;
    width: 4vmax;
  }

  .detailsBlock-3-1-1 > input {
    padding: 1.5vmax;
    width: 7.5vmax;
    font: 500 2.2vmax "Roboto";
  }

  .detailsBlock-3-1 > button:last-child {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

  .detailsBlock-3 > p {
    padding: 2.3vmax 0;
    text-align: center;
    font: 600 2vmax "Roboto";
  }

  .detailsBlock-4 {
    font: 550 2.5vmax sans-serif;
    margin-top: 20px;
  }

  .detailsBlock-4 > p {
    font: 400 2.5vmax "Roboto";
  }

  .submitReview {
    font: 500 1.7vmax "Roboto";
    padding: 1.5vmax;
    width: 20vmax;
    margin: 3vmax 0;
  }

 /* Reduce the size of the share buttons */
 .share-btn {
  width: 50px; /* Smaller circle diameter */
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Adjust icon size for mobile */
.share-btn i {
  font-size: 23px; /* Smaller icons for smaller buttons */
}

/* Reduce the gap between buttons */
.share-buttons {
  gap: 12px; /* Reduce gap for mobile */
  margin-right: 60px;
}

/* Adjust the share text size for mobile */
.share-text {
  font-size: 19px; /* Smaller text for mobile */
  margin-right: 15px; /* Adjust margin for balance */
}

/* Button hover effects on mobile */
.share-btn:hover {
  transform: translateY(-3px); /* Less intense hover lift on mobile */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15); /* Slightly smaller shadow for mobile */
}

.reviewCard > p {
   font: 600 3vw "Roboto";
}

.reviewCardComment {
  font: 300 5vw cursive;
}

.reviewsHeading {
  font-size: medium;
  margin-top: 50px;
}

.noReviews {
  font: 400 1.4vmax "Gill Sans";
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
}

.related-products-section {
  position: relative;
  padding-bottom: 30px; /* Adjust padding for pagination space */
  width: 415px;
  height: 590px;
}

.relatedheading {
  color: #000000be;
  font: 600 2vmax "Roboto", sans-serif;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 22vmax;
  margin: auto;
  margin-bottom: 6vmax;
  margin-top: 20px;
}

.swiper-pagination {
  position: relative; 
  text-align: center;
  margin-bottom: -3px; 
}

.swiper-pagination-bullet {
  background-color: #222; 
  opacity: 0.7; 
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.swiper-pagination-bullet-active {
  background-color: #FFD700; /* Golden color for the active dot */
  transform: scale(1.2); /* Slightly enlarge the active dot */
  opacity: 1;
}

/* Add smooth background transition */
.swiper-container {
  padding-bottom: 40px;
  background-position: 100% 0;
  transition: background-position 0.5s ease-in-out; /* Smooth movement */
}

}
