.speedDial {
  position: fixed;
  right: 1vmax;
  top: 1.2vmax;
}

.speedDialIcon {
  width: 68px;
  height: 68px;
  border-radius: 100%;
}

/* Dropdown button */
.dropbtn {
  display: flex;
  align-items: center; /* Align content on the same line */
  background-color: transparent;
  color: black;
  font-size: 15px;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-family: "Roboto";
  letter-spacing: 0.3px;
  position: relative;
  transition: color 0.3s ease; /* Smooth transition for text color */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-link {
  text-decoration: none;
  color: inherit;
  margin-right: 5px; /* Add space between the text and the arrow */
}

/* Arrow for the dropdown */
.arrow {
  display: inline-block;
  width: 0;
  height: 8px;
  margin-left: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}

/* Underline animation */
.dropbtn::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(161, 161, 225); /* Underline color */
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

/* Hover state for underline and color change */
.dropdown:hover .dropbtn::after {
  visibility: visible;
  width: 90%; /* Underline expands to full width */
}

.dropdown:hover .dropbtn {
  color: rgb(161, 161, 225); /* Change text color on hover */
}

/* Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  width: 300px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0px;
}

/* Dropdown links */
.dropdown-content a {
  color: black;
  padding: 9px 12px;
  text-decoration: none;
  display: block;
  font-family: "Roboto";
  font-size: 14px;
  text-align: left;
  justify-content: flex-start;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Add margin to the first item */
.dropdown-content a:first-child {
  margin-top: 17px;
}

/* Add margin to the last item */
.dropdown-content a:last-child {
  margin-bottom: 17px;
}

/* Optional: Add a small margin between items */
.dropdown-content a:not(:first-child):not(:last-child) {
  margin-top: 4px;
}

/* Hover effect for dropdown items */
.dropdown-content a:hover {
  background-color: rgb(171, 171, 215);
}

/* Show dropdown content on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Hover effect for the arrow color */
.dropdown:hover .arrow {
  border-top-color: rgb(161, 161, 225); /* Change arrow color on hover */
}

.navbar{
  height: 2.5vmax;
  margin-top: 40px;
}

.logos {
  text-decoration: none; /* Remove underline */
  padding-left: 140px;
  padding-top: 20px;
  font-family: 'Montserrat', sans-serif; /* A modern and attractive font */
  font-size: 23px; /* Adjust font size as needed */
  color: #222222; /* Text color */
  text-transform: uppercase; /* Uppercase text for emphasis */
  letter-spacing: 2px; /* Increase letter spacing for a modern look */
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
  font-weight: 500;
}

.logos:hover{
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Subtle glow effect */
  }

.banner{
 height:600px;
 padding-top:54px;
}

.menuBurger{
  padding-bottom: 0px;
  padding-top: 8px;
  padding-right: 47px;
  padding-left: 25px;
  margin-top: -5px;
 }

.burger-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.nav-search {
  width: 45vw;
  display: flex;
  align-items: center;
  background-color:  transparent;
  padding: 5px 20px 5px 10px; 
  position: absolute;
  top: 17px;
  right: 380px;
  box-shadow: 0 0px 1.5px rgba(0, 0, 0, 0.1);
  color: #222222;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 10px;
  font: 300 1.1vmax "playfair display";
  margin-right: 147px;
  font-size: 1rem;
  flex: 1;
  padding-left: -36px;
}

.search-input:hover {
  color: rgb(152, 152, 255);
}

.search-icon {
  background-color: rgb(161, 161, 225); /* Black background similar to the screenshot */
  border: 0;
  outline: 0;
  color: #f1f1f1; /* White color for the search icon */
  font-size: 18px; /* Decreased font-size slightly to give a lighter appearance */
  line-height: 1; /* Adjusted line-height for better alignment */
  cursor: pointer;
  padding: 13px 30px; /* Add padding for better alignment */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 190px; /* Adjusted margin for better positioning */
  transition: 0.35s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.search-icon:hover {
  color: #222222; /* Hover effect color */
  letter-spacing: -0.5px; /* Tighten the spacing for a thinner appearance on hover */
}

.iconss {
  display: flex;
  align-items: center;
  gap: 1.2vmax; /* Adjust gap between icons */
  margin-left: 835px;
}

.cart-badge {
  position: fixed;
  top: 21px;
  right: 70px;
  background-color: #222;
  color: rgb(161, 161, 225);
  border-radius: 50%;
  width: 2.1em; /* Fixed width */
  height: 2.1em; /* Fixed height */
  line-height: 2.1em; /* Center text vertically */
  font-size: 10.5px; /* Adjust font size */
  font-weight: bold;
  text-align: center; /* Center text horizontally */
  padding: 0; /* Remove padding to avoid size issues */
  pointer-events: none; /* Prevent hover interaction */
}

.cart-badge:hover {
  position: fixed !important;
  top: 25px !important;
  right: 70px !important;
}

.cart-badges {
  position: fixed;
  top: 23px;
  right: 131px;
  background-color: rgb(161, 161, 225);
  color: #222;
  border-radius: 50%;
  width: 2.1em; /* Fixed width */
  height: 2.1em; /* Fixed height */
  line-height: 2.1em; /* Center text vertically */
  font-size: 10.5px; /* Adjust font size */
  font-weight: bold;
  text-align: center; /* Center text horizontally */
  padding: 0; /* Remove padding to avoid size issues */
  pointer-events: none; /* Prevent hover interaction */
}

.cart-badges:hover {
  position: fixed !important;
  top: 25px !important;
  right: 70px !important;
}

.cart-icons, .phone-icons {
  font-size: 1.7vmax;
  color: rgb(161, 161, 225);
  cursor: pointer;
  transition: color 0.3s ease;
}

.phone-icons {
  transform: scaleX(-1);
}

.cart-icons:hover, .phone-icons:hover {
  color: #ABB747;
}

/* Icon container styling */
.iconss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.phone-icons, .cart-icons, .auth-button {
  font-size: 24px;
  color: rgb(161, 161, 225);
  transition: color 0.3s ease;
}

.phone-icons:hover, .cart-icons:hover, .auth-button:hover {
  color: #555; /* Slightly changes the icon color when hovering */
}

.auth-buttons {
  display: flex;
  align-items: center;
}

.auth-button {
  padding: 0.1vmax 0.1vmax;
  color: rgb(161, 161, 225);
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.7vmax;
  margin-right: 100px;
}

/* Add this to your CSS file */
.category-links {
  display: flex;
  justify-content: center; /* Center the items */
  gap: 25px; /* Decrease gap between items */
  padding: 30px 10px;
  background-color: white;
  margin-top: 20px;
}

.category-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.category-links img {
  border: 3px solid #ffc0cb; /* Light pink border color for a softer look */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Soft, elegant shadow */
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 8px;
}

.category-links span {
  font-size: 0.9rem;
  text-align: center;
  font-family: "playfair display";
}

/* New mobile responsive styles */
@media screen and (max-width: 768px) {

  /* Adjusted styles for .speedDial and .speedDialIcon */
  .speedDial {
    margin-top: 27px; /* Adjust as needed for spacing */
    margin-right: -2px;
    width: 50px;
    height: 50px;
  }

  /* Your existing styles */
  .logos {
    margin-top: 13px;
    padding-left: 10px;
    padding-top: 8px;
    font-size: 20px; /* Adjust font size for smaller screens */
    letter-spacing: 0.6px; /* Adjust letter spacing for smaller screens */
    color: #222222; /* Darker color */
    font-weight: 500; /* Adjusted font weight to match the screenshot */
  }
  
  .logos p {
    padding-right: 490px;
    padding-top: 0;
    font-size: 19px; /* Adjust font size for smaller screens */
    letter-spacing: 0.6px; /* Adjust letter spacing for smaller screens */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow text */
    text-align: center; /* Center align text */
    color: #222222; /* Darker color */
    font-weight: 500; /* Adjusted font weight to match the screenshot */
  }
  
  .navbar {
    height: auto;
    justify-content: center;
    padding: 10px 10px; /* Adjust padding for consistency */
    margin-bottom: 0;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
    margin-right: 20px; /* Move the navbar a bit to the left */
    margin-top: 30px;
    color: #222222;
    background-color: transparent;
  }
  
  .burger-icon {
    position: relative;
    margin-right: 0; /* Center align */
    margin-bottom: 14px; /* Space below burger icon */
  }
  
  .menuBurger {
    padding: 8px 25px 0 25px; /* Adjust padding */
    height: 25px; /* Auto height */
    margin-top: 23px;
  }

  .nav-search {
    position: static;
    width: 100%; /* Full width */
    max-width: 100%; /* Adjust as needed */
    margin: 8px 119% -25px 7px; /* Center align with more margin on the left */
    padding: 10px 5px; /* Adjust padding as needed */
    padding-left: 10px;
    box-sizing: border-box; /* Include padding in width calculation */
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Space out children */
    align-items: center; /* Center align items vertically */
    padding-right: 18px;
    padding-left: 8px;
  }
  
  .search-input {
    font-size: 14px; /* Adjust font size for mobile */
    width: calc(100% - 22px); /* Adjust width to fit within container considering the width of .search-select */
    color: #000000;
    font-family: "Playfair Display";
    padding-left: 6px; /* Add some padding to bring text a bit to the left */
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  .search-icon {
    font-size: 17px; /* Reduce the font size on mobile */
    padding: 14px 24px; /* Adjust padding for mobile */
    margin-left: 6px; /* Adjust margin for mobile */
    margin-right: -18px;
  }
  
  .auth-buttons {
    display: flex; /* Use flexbox for layout */
    justify-content: flex-end; /* Move items to the right end of the container */
    margin-left: auto; /* Push auth buttons to the right */
    order: -1;
    margin-left: -232px;
    margin-top: -101px;
  }
  
  .auth-button {
    width: 80%; /* Smaller width */
    margin-bottom: -15px; /* Adjust space below each button */
    text-align: center; /* Center align text */
    padding: 9px 9px; /* Increase padding for larger button size */
    font-size: 1.2rem;
    margin-left: 200px;
  }

  .iconss {
    display: flex;
    align-items: center;
    gap: 1.2vmax; /* Adjust gap between icons */
    margin-left: -240px;
  }

  .cart-badge {
    position: fixed;
    top: 36px;
    right: 60px;
    background-color: #222222;
    color: #fff;
    border-radius: 50%;
    width: 2.1em; /* Fixed width */
    height: 2.1em; /* Fixed height */
    line-height: 2.1em; /* Center text vertically */
    font-size: 10.5px; /* Adjust font size */
    font-weight: bold;
    text-align: center; /* Center text horizontally */
    padding: 0; /* Remove padding to avoid size issues */
    pointer-events: none; /* Prevent hover interaction */
  }
  
  .cart-badge:hover {
    position: fixed !important;
    top: 25px !important;
    right: 70px !important;
  }
  
  .cart-badges {
    position: fixed;
    top: 35px;
    right: 60px;
    background-color: #222222;
    color: #fff;
    border-radius: 50%;
    width: 2.1em; /* Fixed width */
    height: 2.1em; /* Fixed height */
    line-height: 2.1em; /* Center text vertically */
    font-size: 10.5px; /* Adjust font size */
    font-weight: bold;
    text-align: center; /* Center text horizontally */
    padding: 0; /* Remove padding to avoid size issues */
    pointer-events: none; /* Prevent hover interaction */
  }
  
  .cart-badges:hover {
    position: fixed !important;
    top: 25px !important;
    right: 70px !important;
  }

  .cart-icons, .phone-icons {
    margin-top: -92px;
    font-size: 1.2rem;
  }

  .category-links {
    justify-content: space-evenly;
    flex-wrap: nowrap;
    overflow-x: auto; /* Enable horizontal scrolling */
    padding: 2px 0;
  }

  .category-links a {
    flex: 0 0 auto; /* Prevent items from shrinking */
    margin: 17px 2px; /* Add margin between items for spacing */
  }

  .category-links img {
    border: 2.3px solid #A1A1E1; /* Reduce border width for mobile */
    width: 70px; /* Reduce image width for mobile */
    height: 70px; /* Reduce image height for mobile */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Soft, elegant shadow */
  }

  .category-links span {
    font-family: "Playfair Display", serif;
  }
}

@media (max-width: 768px) {
  .dropdown {
    display: none;
  }
}
