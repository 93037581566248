.confirmOrderPage {
  height: 155vh;
  background-color: white;
  display: grid;
  grid-template-columns: 6fr 3fr;
}

.confirmOrderPage > div:last-child {
  border-left: 1px solid rgba(0, 0, 0, 0.247);
}

.confirmshippingArea {
  padding: 5vmax;
  padding-bottom: 0%;
}

.confirmshippingArea > p {
  font: 400 1.8vmax "Roboto";
}

.confirmshippingAreaBox,
.confirmCartItemsContainer {
  margin: 2vmax;
}

.confirmshippingAreaBox > div {
  display: flex;
  margin: 1vmax 0;
}

.confirmshippingAreaBox > div > p {
  font: 400 1vmax "Roboto";
  color: black;
}
.confirmshippingAreaBox > div > span {
  margin: 0 1vmax;
  font: 100 1vmax "Roboto";
  color: #575757;
}

.confirmCartItems > p {
  font: 400 1.8vmax "Roboto";
}

.confirmCartItems {
  padding: 5vmax;
  padding-top: 2vmax;
}

.confirmCartItemsContainer {
  max-height: 20vmax;
  overflow-y: auto;
}

.confirmCartItemsContainer > div {
  display: flex;
  font: 400 1vmax "Roboto";
  align-items: center;
  justify-content: space-between;
  margin: 2vmax 0;
}

.confirmCartItemsContainer > div > img {
  width: 3vmax;
}

.confirmCartItemsContainer > div > a {
  color: #202020;
  margin: 0 2vmax;
  width: 60%;
  text-decoration: none;
}

.confirmCartItemsContainer > div > span {
  font: 100 1vmax "Roboto";
  color: #5e5e5e;
}

.orderSummary {
  padding: 7vmax;
  margin-top: -30px;
}

.orderSummary > p {
  text-align: center;
  font: 400 1.8vmax "Roboto";
  border-bottom: 1px solid rgba(0, 0, 0, 0.267);
  padding: 1vmax;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.orderSummary > div > div {
  display: flex;
  font: 300 1vmax "Roboto";
  justify-content: space-between;
  margin: 2vmax 0;
}
.orderSummary > div > div > span {
  color: rgba(0, 0, 0, 0.692);
}

.orderSummaryTotal {
  display: flex;
  font: 300 1vmax "Roboto";
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.363);
  padding: 2vmax 0;
}

.orderSummary > button {
  background-color: #222222;
  color: white;
  width: 100%;
  padding: 1vmax;
  border: none;
  margin: auto;
  cursor: pointer;
  transition: 0.5s;
  font: 400 1vmax "Roboto";
}

.orderSummary > button:hover {
  background-color: rgb(161, 161, 225);
  transform: scale(1.1);
}

.payment-method {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 10px auto;
  transition: background-color 0.3s ease;
  margin-bottom: 30px;
  width: 300px;
}

.payment-method p {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.payment-method label {
  display: block;
  margin: 15px 0;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  padding-left: 35px;
}

.payment-method input[type="radio"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  appearance: none;
  background-color: #fff;
  border: 2px solid #999;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.payment-method input[type="radio"]:checked {
  background-color: #4caf50;
  border-color: #4caf50;
}

.payment-method label:hover {
  transform: scale(1.05);
}

.payment-method input[type="radio"]:hover {
  border-color: #4caf50;
}

.payment-method label:hover input[type="radio"] {
  border-color: #4caf50;
}

.payment-method label::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.payment-method input[type="radio"]:checked + label::before {
  background-color: #4caf50;
}

.payment-method label:focus-within {
  box-shadow: 0 0 5px #4caf50;
}

.coupon-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 10px; /* Add gap between input and buttons */
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.coupon-container input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.coupon-container input:focus {
  border-color: #007bff;
  outline: none;
}

.coupon-container button {
  padding: 10px 15px;
  background-color: rgb(139, 139, 254);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transition for transform */
}

/* Hover effect for buttons */
.coupon-container button:hover {
  background-color: #326ba8;
  transform: translateY(-2px); /* Lift the button slightly on hover */
}

/* Active effect when the button is clicked */
.coupon-container button:active {
  transform: translateY(0); /* Return to original position when clicked */
}

/* Disabled state */
.coupon-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7; /* Add some transparency to disabled buttons */
}


@media screen and (max-width: 600px) {
  .confirmOrderPage {
    grid-template-columns: 1fr;
    height: unset;
  }

  .confirmOrderPage > div:last-child {
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.247);
  }

  .confirmshippingArea > p {
    font: 400 6vw "Roboto";
  }

  .confirmshippingAreaBox > div {
    display: flex;
    margin: 6vw 0;
  }

  .confirmshippingAreaBox > div > p {
    font: 400 4vw "Roboto";
  }
  .confirmshippingAreaBox > div > span {
    font: 100 4vw "Roboto";
  }

  .confirmCartItems > p {
    font: 400 6vw "Roboto";
  }

  .confirmCartItemsContainer {
    max-height: 50vw;
  }

  .confirmCartItemsContainer > div {
    font: 400 4vw "Roboto";
    margin: 4vw 0;
  }

  .confirmCartItemsContainer > div > img {
    width: 10vw;
  }

  .confirmCartItemsContainer > div > a {
    margin: 0;
    width: 30%;
  }

  .confirmCartItemsContainer > div > span {
    font: 100 4vw "Roboto";
  }

  .orderSummary {
    padding: 12vw;
  }

  .orderSummary > p {
    font: 400 6vw "Roboto";
    padding: 4vw;
  }

  .orderSummary > div > div {
    font: 300 4vw "Roboto";
  }

  .orderSummaryTotal {
    font: 300 4vw "Roboto";
    padding: 5vw 0;
  }

  .orderSummary > button {
    padding: 4vw;
    margin: 4vw auto;
    font: 400 4vw "Roboto";
    transform: scale(1.1);
  }
}
