.CartItemCard {
  display: flex;
  padding: 1vmax;
  height: 8vmax;
  align-items: flex-start;
  box-sizing: border-box;
}

.CartItemCard > img {
  width: 5vmax;
}

.CartItemCard > div {
  display: flex;
  margin: 0.3vmax 1vmax;
  flex-direction: column;
}

.CartItemCard > div > a {
  font: 300 1.15vmax "Playfair Display", serif;
  color: #222222;
  text-decoration: none;
}

.CartItemCard > div > span {
  font: 300 1.1vmax "Playfair Display", serif;
  color: #222222;
}

.CartItemCard > div > p {
  color: red;
  font: 100 1vmax "Playfair Display", serif;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  
  .CartItemCard {
    padding: 2vmax;
    height: 26vmax;
  }

  .CartItemCard > img {
    width: 10vmax;
    margin-left: -7px;
  }

  .CartItemCard > div {
    margin: 0.1vmax 1vmax;
    margin-right: 15px;
  }

  .CartItemCard > div > a {
    font: 300 1.8vmax "Playfair Display", serif;
  }

  .CartItemCard > div > span {
    font: 300 1.8vmax "Playfair Display", serif;
  }

  .CartItemCard > div > p {
    font: 100 1.8vmax "Playfair Display", serif;
    margin-top: 5px;
  }
}
