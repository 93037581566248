.searchBox {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9f3ff;
  position: fixed;
  top: 0%;
  left: 0;
}

.search-logo {
  width: 220px;
  height: auto;
  padding-right: 80px;
  transition: transform 0.4s ease, box-shadow 0.4s ease; /* Smooth transitions */
}

.search-logo:hover {
  transform: scale(1.05); /* Slight grow on hover */
}

.searchBox > input[type="text"] {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.274);
  background-color: white;
  border: none;
  color: rgba(0, 0, 0, 0.637);
  padding: 1vmax 2vmax;
  width: 50%;
  outline: none;
  border-radius: 0%;
  font: 500 1.2vmax "Playfair Display", serif;
  box-sizing: border-box;
  height: 8%;
}

.searchBox > input[type="submit"] {
  height: 8%;
  border-radius: 0%;
  background-color: #222222;
  border: none;
  padding: 1vmax;
  width: 10%;
  font: 300 1.2vmax "Playfair Display", serif;
  cursor: pointer;
  color: white;
  transition: all 0.5s;
  margin-right: 30px;
}

.searchBox > input[type="submit"]:hover {
  background-color: rgb(152, 152, 255);
}

@media screen and (max-width: 768px) {

  .search-logo {
    width: 310px;
    height: auto;
    margin: -90px auto; /* Center the logo horizontally */
    padding-bottom: 140px; /* Adjust for spacing from the top */
    display: block;
    padding-left: 80px;
  }

  .searchBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150vh; /* Increased height for better centering */
    padding: 20px;
  }

  .searchBox > input[type="text"] {
    width: 105%; /* Increased width for better visibility */
    font: 300 4vw "Playfair Display", serif;
    height: 5%; /* Adjusted for better appearance */
    padding: 10px;
    margin-bottom: 20px; /* Add margin for spacing */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.274);
    background-color: white;
    border: none;
    color: rgba(0, 0, 0, 0.637);
    border-radius: 5px;
  }

  .searchBox > input[type="submit"] {
    height: 4.5%;
    width: 45%; /* Wider submit button */
    font: 400 4vw "Playfair Display", serif;
    padding: 1.5vmax;
    margin-bottom: 600px;
    background-color: #222222;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 30px;
    margin-top: 10px;
  }

  .searchBox > input[type="submit"]:hover {
    background-color: rgb(55, 97, 214);
  }
}
