.sidebar {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
}

.sidebar > a:first-child {
  padding: 0;
}

.sidebar > a {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.sidebar > a > img {
  width: 60%;
  transition: all 0.5s;
}

.sidebar > a > img:hover {
  filter: drop-shadow(0 0 10px rgb(161,161,225));
}

.sidebar a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.493);
  font: 200 1rem "Roboto";
  padding: 2rem;
  transition: all 0.5s;
}
.sidebar a:hover {
  color: rgb(161,161,225);
  transform: scale(1.1);
}

.sidebar a > P {
  display: flex;
  align-items: center;
}
.sidebar a > p > svg {
  margin-right: 0.5rem;
}

.MuiTypography-root {
  background-color: #fff !important;
}

@media screen and (max-width: 600px) {
  .sidebar > a > img {
    width: 55%;
    transition: all 0.5s;
  }
}