/* Heading and general product styles */
.productsHeadingss {
  margin: 2.8vmax auto;
  width: 15vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.171);
  padding: 2vmax;
  color: rgba(0, 0, 0, 0.731);
  font: 500 1.6vmax "playfair display";
  text-align: center;
  font-weight: bold;
}

/* Heading and general product styles */
.productsHeadings {
  margin: 2.8vmax auto;
  width: 15vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.171);
  padding: 2vmax;
  color: rgba(0, 0, 0, 0.731);
  font: 500 1.6vmax "playfair display";
  text-align: center;
  font-weight: bold;
}

/* Categories container and toggle button */
.categories-containers {
  position: fixed; /* Keep it fixed relative to the viewport */
  top: 11.3%; /* Adjust as needed */
  right: 3%; /* Adjust the horizontal position */
  display: flex;
  align-items: center;
}

/* Dropdown category box styles */
.categoryBoxs {
    list-style: none;
    position: absolute;
    top: 14.1%;
    right: 3%;
    background-color: #222222;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    width: 200px; /* Set a fixed width for better control */
  }
  
  .categoryBoxs.show {
    max-height: 15vmax;
  }
  
  .categoryBoxs.hide {
    max-height: 0;
  }

  /* Responsive styles */
@media screen and (max-width: 700px) {

  .productsHeadingss {
    padding-right: 55px;
    font-size: 19px;
    margin-top: 38px;
    font-weight: bold;
    width: 31vw;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.171);
  }

  .productsHeadings {
    padding-right: 75px;
    font-size: 19px;
    margin-top: 38px;
    font-weight: bold;
    width: 22vw;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.171);
  }

  .categories-containers {
    top:11.3%;
    right: 1%;
  }

    /* Dropdown category box styles */
  .categoryBoxs {
    top: 12.5%;
    right: 1%;
    width: 180px; /* Set a fixed width for better control */
    height: 350px;
  }
  
  .categoryBoxs.show {
    max-height: 28vmax;
  }
  
  .categoryBoxs.hide {
    max-height: 0;
  }
  
}