.MuiStepConnector-line {
  display: none !important;
}

.MuiStepConnector-root {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.349);
}

.MuiStepConnector-active,
.MuiStepConnector-completed {
  background-color: #222222;
}

#root > div.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.MuiPaper-elevation0 {
  margin-top: 30px; 
  box-sizing: border-box;
}