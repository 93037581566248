.processOrderTrackingContainer {
  flex-direction: column;
  display: flex; /* Use flexbox to align elements */
  margin-top: 10px; /* Add some space above */
}

.trackingIdSection {
  flex-direction: column;
  margin-left: 60px; /* Space between the two sections */
  flex: 1; /* Allow it to take available space */
  align-items: center; /* Align items vertically */
  display: flex; /* Flexbox layout for alignment */
  margin-bottom: 550px;
}

.trackingIdSection h1 {
  margin: 1vmax 0;
  padding: 1vmax;
  background-color: white;
  margin-right: 70px;
  font: 300 1.8vmax "Playfair Display", serif;
}

.trackingIdSection input {
  display: flex;
  width: 90%;
  align-items: center;
  margin-right: 150px;
  padding: 10px;
}

/* Add button styles */
.trackingIdSection button {
  align-items: center;
  margin-top: 10px;
  margin-left: 90px;
  background-color: #007bff; /* Primary button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  padding: 8px 25px; /* Padding for the button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

.trackingIdSection button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.trackingIdDisplay {
  background-color: #f9f9f9; /* Light grey background */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Spacing inside the container */
  margin-top: 11px; /* Spacing above the section */
  display: inline-block; /* Ensure the container fits content */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin-bottom: 20px;
  margin-right: 20px;
}

.trackingIdDisplay h3 {
  font-size: 1.2rem; /* Medium font size */
  color: #333; /* Dark grey text */
  font-weight: 700; /* Slightly bold for emphasis */
  margin: 0; /* Remove margin to keep in one line */
  display: inline-block; /* Keep the heading in the same line */
}

.trackingIdDisplay h2 span {
  font-size: 1.25rem; /* Same size for the tracking ID */
  color: #007bff; /* Blue color for the tracking ID */
  font-weight: bold; /* Make the tracking ID bold */
}

.processOrderTrackingContainer h1{
  font:300 2vmax "Playfair Display", serif; 
}

.confirmCartItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between items */
}

.confirmCartItemsContainer > div {
  display: flex;
  align-items: center;
  gap: 15px; /* Spacing between image, name, size, and price */
  border-bottom: 1px solid #ddd; /* Optional: Add a border to separate items */
  padding: 10px 0;
}

.confirmCartItemsContainer img {
  width: 80px; /* Adjust width as needed */
  height: auto;
  border-radius: 5px; /* Optional: Add rounded corners to images */
}

.confirmCartItemsContainer a {
  flex: 1; /* Allow the link to take available space */
  color: #333; /* Dark color for text */
  text-decoration: none;
  font-weight: bold;
}

.confirmCartItems h2{
  font:300 2.5vmax "Playfair Display", serif; 
}

.confirmshippingArea{
  font:300 2.5vmax "Playfair Display", serif; 
}

.orderItemSize {
  flex: 0 0 auto; /* Prevent size container from growing or shrinking */
  font-size: 14px; /* Adjusted font size for better readability */
  color: #fff; /* White text color for better contrast on dark background */
  padding: 0.5vmax 1vmax; /* Adjusted padding for balanced spacing */
  background-color: #28a745; /* Bootstrap green color for better visual appeal */
  border-radius: 4px; /* Rounded corners for a modern look */
  font-size: 1vmax; /* Consistent font size */
  text-align: center; /* Center text within the size container */
  display: inline-block; /* Ensures the size container fits content */
}

.confirmCartItemsContainer span {
  font-size: 14px;
  color: #333; /* Dark color for text */
  display: flex;
  align-items: center;
  gap: 10px; /* Spacing between quantity and price */
}

.confirmCartItemsContainer b {
  color: #000; /* Bold price color */
}

.newProductContainer{
  height: fit-content;
}

.updateOrderForm {
  margin: 4vmax 0;
  padding: 3vmax;
  background-color: white;
}

.updateOrderForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}
.updateOrderForm > div > select {
  padding: 1vmax 4vmax;
  margin: 2rem 0;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax cursive;
  outline: none;
}

.updateOrderForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

/* Mobile Styles */
@media screen and (max-width: 600px) {
  
  .confirmCartItems h2{
    font:300 3.2vmax "Playfair Display", serif; 
  }

  .processOrderTrackingContainer h1{
    font: 300 2.9vmax "Playfair Display", serif; 
  }

  .confirmCartItemsContainer {
    gap: 10px; /* Reduced spacing between items */
  }

  .confirmCartItemsContainer > div {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start */
    gap: 10px; /* Reduced spacing for mobile */
    border-bottom: none; /* Remove border on mobile */
    padding: 10px 0; /* Reduced padding */
  }

  .confirmCartItemsContainer img {
    width: 60px; /* Smaller image width for mobile */
  }

  .confirmCartItemsContainer a {
    font-size: 12px; /* Smaller font size for mobile */
  }

  .orderItemSize {
    font-size: 12px; /* Smaller font size */
    padding: 0.5vmax; /* Reduced padding */
  }

  .confirmCartItemsContainer span {
    font-size: 12px; /* Smaller font size */
    gap: 5px; /* Reduced spacing between elements */
  }

  .updateOrderForm {
    padding: 5vmax; /* Increased padding for mobile */
  }

  .updateOrderForm > div > select {
    padding: 2.5vmax 5vmax; /* Larger padding */
    font: 300 1.7vmax cursive; /* Larger font size */
  }

  .updateOrderForm > div > svg {
    font-size: 2.8vmax; /* Larger icon size for mobile */
  }
}
