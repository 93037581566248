.categoryBoxsss{
    list-style: none;
    position: absolute;
    top: 13.1%;
    right: 3%;
    background-color: #222222;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    width: 220px; /* Set a fixed width for better control */
   height: 200px;
}

/* Categories container and toggle button */
.categories-containersss {
  position: fixed; /* Keep it fixed relative to the viewport */
  top: 10.5%; /* Adjust as needed */
  right: 3%; /* Adjust the horizontal position */
  display: flex;
  align-items: center;
 }

.categoryBoxsss.show {
    max-height: 25vmax;
  }
  
  .categoryBoxsss.hide {
    max-height: 0;
  }

  @media screen and (max-width: 700px) {
    .categoryBoxsss {
        top: 11.3%;
        right: 1%;
        width: 200px; /* Set a fixed width for better control */
        height: 650px;
      }

      /* Categories container and toggle button */
      .categories-containersss {
        position: fixed; /* Keep it fixed relative to the viewport */
        top: 10.3%; /* Adjust as needed */
        right: 1.3%; /* Adjust the horizontal position */
        display: flex;
        align-items: center;
      }
      
      .categoryBoxsss.show {
        max-height: 35vmax;
      }
      
      .categoryBoxsss.hide {
        max-height: 0;
      }
  }  