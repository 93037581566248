/* General container for product form */
.newProductContainer {
  width: 100%;
  box-sizing: border-box;
  background-color: rgb(221, 221, 221);
  border-left: 1px solid rgba(0, 0, 0, 0.158);
  display: flex;
  flex-direction: column;
  height: auto;
}

/* Header styling */
.newProductContainer h1 {
  color: rgba(0, 0, 0, 0.733);
  font: 400 1.5rem "Roboto";
  text-align: center;
  border-bottom: 2px solid rgba(11, 10, 10, 0.171);
}

/* Product form styling */
.createProductForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2.5vmax;
  justify-content: space-between;
  height: auto;
  max-width: 80vh;
  background-color: #f9f9f9;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-top: 60px;
  margin-bottom: 200px;
  gap: 1.5vmax;
}

/* Ensures consistency in spacing and alignment */
.createProductForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.createProductForm > div > input,
.createProductForm > div > select,
.createProductForm > div > textarea {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax "Roboto";
  outline: none;
}

.createProductForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

/* File input styling */
#createProductFormFile > input {
  display: flex;
  padding: 0%;
}

#createProductFormFile > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax "Roboto";
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#createProductFormFile > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

#createProductFormImage {
  width: 100%;
  overflow: auto;
}

#createProductFormImage > img {
  width: 3vmax;
  margin: 0 0.5vmax;
}

/* Submit button styling */
#createProductBtn {
  border: none;
  background-color: #222222;
  color: white;
  font: 300 0.9vmax "Roboto";
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

#createProductBtn:hover {
  background-color: rgb(77, 75, 75);
}

/* Styling the available sizes section */
.sizesCheckbox {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px 0;
}

/* Style for each size option */
.sizesCheckbox label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  transition: color 0.3s ease, transform 0.2s ease;
}

/* Checkbox styling */
.sizesCheckbox input[type="checkbox"] {
  margin-right: 10px;
  accent-color: #007bff;
  transform: scale(1.2);
}

.sizesCheckbox label:hover {
  color: #0056b3;
  transform: scale(1.05);
}

.sizesCheckbox input[type="checkbox"]:checked + span {
  font-weight: bold;
  color: #007bff;
}

.sizesCheckbox input[type="checkbox"]:checked {
  border: 2px solid #007bff;
  border-radius: 4px;
  background: #e7f0ff;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 600px) {
  .newProductContainer {
    background-color: rgb(255, 255, 255);
    margin-bottom: 10px;
  }

  .createProductForm {
    padding: 7vmax 18px;
    height: auto;
    max-width: 90vw;
    margin: 20px auto;
    gap: 2vmax;
  }

  .createProductForm > div > input,
  .createProductForm > div > select,
  .createProductForm > div > textarea {
    padding: 2.5vmax 4vmax;
    font: 300 1.7vmax "Roboto";
  }

  .createProductForm > div > svg {
    font-size: 2.8vmax;
  }

  #createProductFormFile > img {
    width: 8vmax;
    border-radius: 100%;
  }

  #createProductFormFile > input::file-selector-button {
    height: 7vh;
    font: 400 1.8vmax "Roboto";
  }

  #createProductBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}
