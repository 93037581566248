/* Heading and general product styles */
.productsHeadingsss {
    margin: 2.8vmax auto;
    width: 15vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.171);
    padding: 2vmax;
    color: rgba(0, 0, 0, 0.731);
    font: 500 1.6vmax "playfair display";
    text-align: center;
    font-weight: bold;
  }

.categoryBoxss{
    list-style: none;
    position: absolute;
    top: 13.3%;
    right: 3%;
    background-color: #222222;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    width: 240px; /* Set a fixed width for better control */
}

.categoryBoxss.show {
    max-height: 65vmax;
}
  
.categoryBoxss.hide {
    max-height: 0;
}

@media screen and (max-width: 700px) {

  .productsHeadingsss {
      padding-right: 58px;
      font-size: 19px;
      margin-top: 38px;
      font-weight: bold;
      width: 34vw;
      border-bottom: 1.2px solid rgba(0, 0, 0, 0.171);
  }

  .categoryBoxss {
    top: 11.4%;
    right: 1%;
    width: 250px; /* Set a fixed width for better control */
    height: 750px;
  }
  
  .categoryBoxss.show {
    max-height: 89vmax;
  }
  
  .categoryBoxss.hide {
    max-height: 0;
  }

}    