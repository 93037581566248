/* HTML and Body Styles */
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Temporarily hidden to diagnose */
}

/* Container for the "Shop By Price" section */
.shop-by-price {
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  background-color: #f9f9f9; /* Light background for the section */
  border-radius: 10px; /* Softly rounded corners */
}

/* Heading style */
.shop-by-price h2 {
  font-family: 'Playfair Display', serif; /* Playfair font */
  font-size: 2.4rem; /* Large heading size */
  color: #333; /* Dark color for the heading */
  margin-bottom: 20px; /* Space below the heading */
}

/* General button styles */
.btn-under299 {
  background-color: rgb(147, 147, 254); /* Black background */
  color: #fff; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 1.2rem; /* Slightly larger text */
  font-family: 'Playfair Display', serif; /* Consistent font with heading */
  border-radius: 5px; /* Rounded button corners */
  margin: 10px; /* Space between buttons */
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* Smooth animation on hover */
  position: relative;
  overflow: hidden; /* To contain the hover animation */
  margin-bottom: 20px;
}

/* Hover effect */
.btn-under299:hover {
  background-color: #fff; /* White background on hover */
  color: #000; /* Black text on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Light shadow effect on hover */
  animation: pulse 1s infinite;
}

/* Button hover animation: background color fill from left to right */
.btn-under299::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15); /* Translucent overlay */
  transform: skewX(-45deg);
  transition: left 0.5s ease-in-out;
}

/* Hover animation - slide effect */
.btn-under299:hover::before {
  left: 100%; /* Moves the animation overlay */
}

/* Button focus and active states */
.btn-under299:focus, .btn-under299:active {
  outline: none; /* Remove the default button outline */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Slight glow on active */
}

.latest-collection-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.latest-slide-show {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.latest-slide {
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}

.latest-slide img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
}

.latest-slide-name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.latest-slide-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.latest-slide-nav.prev {
  left: 10px;
}

.latest-slide-nav.next {
  right: 10px;
}

.best-collection-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.best-slide-show {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.best-slide {
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}

.best-slide img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
}

.best-slide-name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.best-slide-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

.best-slide-nav.prev {
  left: 10px;
}

.best-slide-nav.next {
  right: 10px;
}

/* Slideshow Container */
.slideshow-container {
  position: relative;
  width: 375px;
  height: 470px;
  margin: -5px auto;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Slides */
.slides {
  display: flex;
  transition: transform 0.6s ease-in-out;
  width: 100%;
  height: 100%;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.slide img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover; /* Ensures the image covers the container */
}

/* Shop Now Button */
.shop-now-button-container {
  position: absolute;
  bottom: 38px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.shop-now-button-container button {
  background-color: #222222; /* Updated background color */
  border: none;
  border-radius: 12px; /* Slightly more rounded corners */
  color: #ffffff;
  padding: 10px 20px; /* Increased padding for a more substantial feel */
  font-size: 0.9rem; /* Slightly larger font size */
  font-weight: bold;
  text-transform: uppercase; /* Uppercase text for emphasis */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Added transition for box-shadow */
}

.shop-now-button-container button:hover {
  transform: translateY(-5px) scale(1.05); /* Slight lift and scale on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.shop-now-button-container button:active {
  transform: translateY(-2px) scale(1); /* Subtle adjustment on click */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Return to original shadow */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.shop-now-button-container button:hover {
  animation: pulse 1s infinite;
}

/* Navigation Dots */
.dot-container {
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

 .active-dot {
  background-color: #343a40;
  transform: scale(1.2);
}

 .dot:hover{
  background-color: #a9ba9d;
}

/* Navigation Arrows */
.prev, .next {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  background-color: #222222;
  color: whitesmoke;
  border: none;
  border-radius: 50%; /* Perfect circle */
  cursor: pointer;
  font-size: 1.3rem;
  padding: 10px;
  width: 40px; /* Width and height for circular appearance */
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.prev:hover, .next:hover {
  background-color: #2d3436; /* Slightly lighter hover color */
  transform: translateY(-50%) scale(1.1); /* Slight zoom effect on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* Deeper shadow on hover */
}

.prev:active, .next:active {
  transform: translateY(-50%) scale(1.05); /* Slightly smaller when clicked */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6); /* More defined shadow on click */
}

.prev {
  left: 15px; /* Adjusted positioning */
}

.next {
  right: 15px; /* Adjusted positioning */
}

/* Adding a subtle bounce effect on hover */
.prev:hover:before, .next:hover:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background: rgba(255, 69, 0, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: ripple 0.6s ease forwards;
}

@keyframes ripple {
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.feature-section {
  display: flex;
  justify-content: space-evenly;
  padding: 50px;
  flex-wrap: wrap; /* Added for better responsiveness */
  gap: 20px;
}

.feature-box {
  display: flex;
  align-items: center; /* Align items horizontally */
  padding: 10px;
  border: 1.3px solid rgb(161, 161, 225);
  border-radius: 6px;
  transition: transform 0.3s;
  max-width: 300px; /* Added to prevent boxes from getting too wide */
  margin: 10px; /* Added margin for spacing between boxes */
}

.feature-box img {
  max-width: 30px;
  margin-right: 10px; /* Adjusted for horizontal spacing */
}

.feature-box p {
  margin: 0;
  font-weight: normal;
  font-size: 1.05rem; /* Adjust font size if needed */
  color: rgb(161, 161, 225); /* Adjust color for better readability */
}

.feature-box:hover {
  transform: scale(1.05);
  border-color:rgb(161, 161, 225);
}

.slider {
  height: calc(90vh - 80px);
  width: 100vw;
  position: relative;
  overflow: hidden;
  margin-top: -40px;
}

.slider .container {
  width: 300vw;
  height: 100%;
  display: flex;
  transition: transform 1s ease;
}

.slider .slide {
  width: 100vw;
  height: 100%;
  position: relative;
}

.slider .container img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}

.homeHeading {
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 1.7vmax;
  border-bottom: 0.8px solid rgba(21, 21, 21, 0.5);
  width: 17vmax;
  padding: 1vmax;
  margin: 4vmax auto; /* Adjusted the top margin to move it up */
  color: #000;
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 80vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.custom-carousel {
  width: 100%; 
  margin: auto;
}

.carousel-item img {
  max-height: 400px; 
  width: auto;
  margin: auto;
}

.slideshow-container {
  overflow: hidden; 
  white-space: nowrap;
  position: relative; 
  width: 100%; 
}

.slide {
  display: inline-block; 
  width: calc(100% / 3); 
  vertical-align: top; 
  transition: transform 0.5s ease; 
  transform: translateX(0);
}

.slide.active {
  opacity: 1;
}

.productCard {
  height:430px;
  width: 21vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  margin: 1.6vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}

.productCard > img {
  height:282px;
  width: 21vmax;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}

.productCard > div {
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 5px;
  font: 300 1vmax "Playfair Display", serif;
}

.productCard > p {
  font-family: "Playfair Display", serif;
  font-size: 1.1vmax;
  margin: 1vmax 0.5vmax;
  margin-bottom: 0;
}

.productCard > span {
  margin: 0.5vmax;
  color: tomato;
  font-family: "Playfair Display", serif;
  font-size: 1vmax;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
  transform: translateY(-1vmax);
}

.col-12{
  margin-bottom: 20px;
}

.col-12 span{
  margin-left: 2px;
}

/* Home.css */
.container {
  margin-top: 20px;
}

.slide-content {
  display: flex;
  justify-content: space-between;
}

.product-wrapper {
  width: 30%;
  margin-right: 10px;
}

.product-wrapper:last-child {
  margin-right: 0;
}

#root .shop{
  margin-top:39px;
  margin-bottom:0px;
 }
 
 #root{
  transform:translatex(0px) translatey(0px);
 } 

 .video-sections {
  cursor: pointer;
  width: 100%;
  margin: 0; /* No margin */
  padding: 0; /* No padding */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.video-sections video {
  width: 100%; /* Full width to cover the section */
  height: 610px; /* Maintain aspect ratio */
  display: block; /* Remove default inline spacing */
  object-fit: cover; /* Ensure the video covers the entire section */
}

 .video-section {
  cursor: pointer;
  width: 100%;
  margin: 0; /* No margin */
  padding: 0; /* No padding */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.video-section video {
  width: 100%; /* Full width to cover the section */
  height: 610px; /* Maintain aspect ratio */
  display: block; /* Remove default inline spacing */
  object-fit: cover; /* Ensure the video covers the entire section */
}

.bulk-orders-section {
  background-color: rgb(215, 215, 228); /* Light beige color */
  padding: 20px;
  text-align: center;
  margin: 0px 0;
  height:450px;
}

.bulk-orders-section h2 {
  font-size: 2rem;
  margin-bottom: 40px;
  margin-top: 30px;
}

.bulk-orders-section .highlight {
  font-weight: bold;
  font-style: italic;
}

.bulk-orders-section p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 1rem 0; /* Adjust the margin as needed */
  font-family: "playfair display"; /* Ensure the font family is appropriate */
  margin-right: 300px;
  margin-left: 300px;
}

.bulk-orders-section .order-now-button {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1.05rem;
  background-color: rgb(146, 146, 220);
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.bulk-orders-section .order-now-button:hover {
  background-color: rgb(118, 118, 207);
  animation: pulse 1s infinite;
}

.productCard{-webkit-box-shadow : -10px 0px 30px -12px rgba(189,189,189,1);
-moz-box-shadow: -10px 0px 30px -12px rgba(189,189,189,1);
box-shadow: -10px 0px 30px -12px rgba(189,189,189,1);
border-radius: 10px}

/* Mobile-specific styles */
.mobile-video-container {
  display: none; /* Hide by default */
}

@media (max-width: 768px) {

  .shop-by-price{
    margin-top: -4px;
  }

  .mobile-video-container {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .mobile-video {
    width: 100%;
    height: auto;
  }

  .desktop-view {
    display: none;
  }

  /* Heading style */
.shop-by-price h2 {
  font-family: 'Playfair Display', serif; /* Playfair font */
  font-size: 2rem; /* Large heading size */
  color: #333; /* Dark color for the heading */
  margin-bottom: 20px; /* Space below the heading */
  margin-top: 10px;
}

/* General button styles */
.btn-under299 {
  background-color: rgb(147, 147, 254); /* Black background */
  color: #fff; /* White text */
  border: none; /* No border */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 1.1rem; /* Slightly larger text */
  font-family: 'Playfair Display', serif; /* Consistent font with heading */
  border-radius: 5px; /* Rounded button corners */
  margin: 10px; /* Space between buttons */
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* Smooth animation on hover */
  position: relative;
  overflow: hidden; /* To contain the hover animation */
}

.bulk-orders-section {
    height: 620px;
}

.bulk-orders-section h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.bulk-orders-section p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 1rem 0;
}

.feature-section {
  grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
  display: grid;
  gap: 20px;
  padding-right: 5px;
  padding-left: 12px;
  margin-right: 10px;
}

.feature-box {
  display: flex; /* Ensure flexbox is used */
  flex-direction: row; /* Horizontal alignment */
  align-items: center; /* Center items vertically */
  padding: 15px;
  border: 1px solid rgb(161, 161, 225);
  border-radius: 5px;
  transition: transform 0.3s;
  max-width: 200px; /* Ensure boxes are responsive */
  margin: 0; /* Remove margin for grid layout */
}

.feature-box img {
  max-width: 25px;
  margin-right: 10px; /* Adjust for horizontal spacing */
}

.feature-box p {
  margin: 0;
  font-weight: normal;
  font-size: 0.9rem; /* Adjust font size if needed */
  color: rgb(161, 161, 225); /* Adjust color for better readability */
}

.feature-box:hover {
  transform: scale(1.05);
  border-color: rgb(161, 161, 225);
}

.slider{
  height: 400px;
}

.btn-info {
  padding: 1vmax 4vmax; /* Adjusted padding for mobile */
  font-size: 2.5vw; /* Adjusted font size for mobile */
}

.banner {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  background-size: contain;
  text-align: center;
}

.banner::after {
  display: none;
}

.banner > h1 {
  font-size: 5vw;
  margin: 10px;
}

.banner > p {
  font-size: 4vw;
  margin: 10px;
}

.banner > a > button {
  width: 60%;
  font-size: 4vw;
  padding: 10px;
}

.homeHeading {
  font-size: 4.7vw;
  width: 180px;
  margin: 6.5vmax auto; /* Adjusted the top margin to move it up */
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
}

.container {
  width: 90vw;
}

.productCard {
  width: 200px;
  margin: 3vw auto;
  height: 420px;
}

/* Ensure all elements respect box-sizing */
* {
  box-sizing: border-box;
}

/* Product Card Image */
.productCard > img {
  height: 260px;
  width: 200px;
  display: block; /* Ensure the image doesn't affect margins */
  margin: 0 auto; /* Center the image horizontally if needed */
}

/* Product Card Container */
.productCard > div {
  margin: 1.5vw;
}

/* Product Card Title */
.productCard > p {
  font-size: 3vw;
  margin: 5px auto; /* Center the text horizontally */
  display: flex;
  justify-content: center; /* Center the text within the container */
  overflow-wrap: break-word; /* Ensure long words break properly */
  text-align: left; /* Align text in the center */
  max-width: 90%; /* Prevent overflow beyond the container */
}


.productCard > span {
  font-size: 3vw;
  display: inline-block; /* Ensures the spans stay in line on mobile */
}

.col-12 {
  display: flex; /* Use flex to keep MRP and Sale Price on the same line */
  flex-direction: column; /* Stack the discount percentage below */
}

.col-12 span {
  margin-left: 2px;
  margin-bottom: 4px;
}

 .discount-percentage {
  margin-top: 0px; /* Add some spacing above the discount percentage */
  font-size: 4vw; /* Adjust font size as needed for readability */
  margin-bottom: -20px;
} 

.shop {
  font-size: 4vw; /* Reduce font size */
  width: 90%; /* Adjust width */
  margin: 5vw auto; /* Adjust margin */
  padding-bottom: 10px;
}
.shop-section {
  padding: 8vw 2.5vw; /* Reduce padding */
  padding-bottom: 30px;
}

.box {
  width: 48%; /* Adjust width for mobile view to fit two boxes side by side */
  margin: 1%; /* Small margin to separate boxes slightly */
  margin-bottom: 19px; /* Margin at the bottom for spacing between rows */
  display: inline-block; /* To place boxes side by side */
  vertical-align: top; /* Align the boxes to the top */
  height: 295px; /* Height of the box */
  border: 1px solid #ddd; /* Optional: border to see the box outline */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow for visual appeal */
  padding: 10px; /* Optional: inner padding for content spacing */
  background-color: #fff; /* Optional: background color */
}

.box-img1,
.box-img2,
.box-img3,
.box-img4,
.box-img5,
.box-img6,
.box-img7,
.box-img8 {
    height: 180px; /* Reduce image height */
    width: 100%; /* Ensure images take up full width of their container */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.box-content p {
    font-size: 3vw; /* Reduce font size */
    margin-bottom: 2.5vw; /* Adjust margin */
    margin-top: 2.4vw; /* Adjust margin */
}

.box-content h2{
  font-size: 20px;
}

.see-more {
    font-size: 10vw; /* Reduce font size */
    padding: 8px 15px; /* Adjust padding */
    margin-top: 2.5vw; /* Adjust margin */
}

.shop-now-button-container button{
padding: 10px 10px; /* Increased padding for a more substantial feel */
font-size: 0.7rem; 
}

.prev {
  left: 5px; /* Adjusted positioning */
}

.next {
  right: 5px; /* Adjusted positioning */
}

.prev, .next{
  font-size: 16px;
}

.custom-carousel div{
  gap: 7px;
}

.video-section{
  margin-top: -5px;
  height: 299px;
}

.video-section video {
  height: 299px; /* Adjust height for smaller screens */
  width: 520px;
}

}
