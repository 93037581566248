/* Ensure the body and html elements take full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Container for input and label */
.inputContainer {
  position: relative;
  margin-bottom: 20px;
}

.inputContainer input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.inputContainer label {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  color: #222222;
  transition: 0.2s all;
  pointer-events: none; /* Disable label click */
  font-size: 15px;
  font-family: "Playfair Display", serif;
}

.inputContainer input:focus + label,
.inputContainer input:not(:placeholder-shown) + label {
  top: -3px;
  left: 40px;
  font-size: 14px;
  color: #222222;
  background-color: white; /* To create an effect that label moves inside the input */
  padding: 0 5px;
}


.switchToRegister,
.switchToLogin {
  color: #000000;
  font-size: 17px;
  font-family: "Playfair Display", serif;
  cursor: pointer;
  text-align: center;
  margin-top: 12px;
  text-decoration: underline; /* Underline the text */
}

.switchToRegister:hover,
.switchToLogin:hover {
  color: #333; /* Optional: Change color on hover */
}

.LoginSignUpContainer {
  width: 100vw;
  height: 110vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0%;
  left: 0;
}

.LoginSignUpBox {
  background-color: white;
  width: 27vw;
  height: 70vh;
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid transparent; /* Transparent border to maintain layout */
  outline: 0.5px solid #cccccc; /* Light gray outline with a thin line */
  outline-offset: 25px; /* Creates space between the box and the outline */
  margin-top: -70px;
}


.login_signUp_toggle {
  display: flex;
  height: 3vmax;
}

.login_signUp_toggle > p {
  color: #000000;
  font: 300 18px "Playfair Display", serif;
  transition: all 0.5s;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 100%;
}

.login_signUp_toggle > p:hover {
  color: #222222;
}

.LoginSignUpBox > div > button {
  background-color: #222222;
  height: 3px;
  width: 50%;
  border: none;
  transition: all 0.5s;
}

.loginForm,
.signUpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 0.5vmax;
  justify-content: space-evenly;
  height: 80%;
  transition: all 0.5s;
}

.signUpForm {
  transform: translateY(-100%) translateX(-100vmax);
}

.loginForm > div,
.signUpForm > div {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative; /* Add this */
}

.loginForm > div > input,
.signUpForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 2.5vmax; /* Adjust this */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 1vmax "Playfair Display", serif;
  outline: none;
}

.loginForm input:focus,
.loginForm input:valid{
  border: 1px solid #000000;
}

.signUpForm input:focus,
.signUpForm input:valid{
  border: 1px solid #000000;
}

.loginForm > div > svg,
.signUpForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.loginForm > a {
  color: #000000;
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
  font: 500 1vmax "Playfair Display", serif;
  margin-top: 10px;
  text-decoration: underline #222222;

}

.loginForm > a:hover {
  color: black;
}

#registerImage > img {
  width: 3vmax;
  border-radius: 100%;
}

#registerImage > input {
  display: flex;
  padding: 0%;
}

#registerImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 5vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax "Roboto";
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#registerImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.loginBtn,
.signUpBtn {
  border: none;
  background-color: #222222;
  color: white;
  font: 300 1vmax "Playfair Display", serif;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
  margin-top: 10px;
}

.loginBtn:hover,
.signUpBtn:hover {
  background-color: rgb(90, 88, 88);
}

.shiftToLeft {
  transform: translateX(-100%);
}

.shiftToNeutral {
  transform: translateX(0%);
}

.shiftToNeutralForm {
  transform: translateX(0%) translateY(-100%);
}

.shiftToRight {
  transform: translateX(100%);
}

.avatarSizeInfo {
  display: flex;
  align-items: center;
  margin: 10px; /* Consistent margin around the container */
  color: rgb(50, 50, 50); /* Adjust color as needed */
}

.avatarSizeInfo p {
  margin: 0; /* Reset margin to avoid unexpected spacing */
  padding-left: 35px; /* Adjust as needed for proper spacing */
  font-size: small;
}

.avatarSizeInfo svg {
  margin-right: 10px; /* Space between svg and the text */
  padding-right: 5px; /* Remove padding to avoid misalignment */
}

.passwordToggleIcon {
  position: absolute;
  right: 1vmax;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.623);
  font-size: 1.2vmax; /* Adjust this value to increase the size */
}

.passwordToggleIcon:hover {
  color: #595e2e;
}

@media screen and (max-width: 600px) {

  .inputContainer label {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: #222222;
    transition: 0.2s all;
    pointer-events: none; /* Disable label click */
    font-size: 15px;
    font-family: "Playfair Display", serif;
  }

  .inputContainer input:focus + label,
.inputContainer input:not(:placeholder-shown) + label {
  top: -3px;
  left: 30px;
  font-size: 14px;
  color: #222222;
  background-color: white; /* To create an effect that label moves inside the input */
  padding: 0 5px;
}

  .passwordToggleIcon {
    font-size:large;
  }

  .LoginSignUpContainer {
    background-color: white;
  }

  .LoginSignUpBox {
    width: 100vw;
    height: 95vh;
    border: none; /* Remove border for mobile view */
    outline: none;
    margin-top: 20px;
  }

  .login_signUp_toggle {
    height: 5vmax;
  }

  .login_signUp_toggle > p {
    font: 300 1.8vmax "Playfair Display", serif;
  }

  .loginForm,
  .signUpForm {
    padding: 5vmax;
  }

  .loginForm > div > input,
  .signUpForm > div > input {
    padding: 2.5vmax 5vmax;
    font: 300 1.7vmax "Playfair Display", serif;
  }

  .loginForm > div > svg,
  .signUpForm > div > svg {
    font-size: 2.8vmax;
  }

  .loginForm > a {
    font: 500 1.8vmax "Playfair Display", serif;
  }

  #registerImage > img {
    width: 8vmax;
    border-radius: 100%;
  }

  #registerImage > input::file-selector-button {
    height: 7vh;
    font: 400 1.8vmax "Roboto";
  }

  .loginBtn,
  .signUpBtn {
    font: 300 1.9vmax "Playfair Display", serif;
    padding: 1.8vmax;
  }

}
