footer {
  margin-top: 0px;
  height: 38vmax; /* Adjust the height as needed */
  padding: 2vmax;
  background-color: whitesmoke;
  display: flex;
  align-items: center;
}

.leftFooter{
margin-top: -40px;
display: flex;
flex-direction: column;
gap: 8px ;
width: 450px;
padding: 22px;
border-radius: 8px;
transition: transform 0.3s ease, box-shadow 0.3s ease;
animation: fadeInUp 1s ease-out;
}

.leftFooter:hover {
  transform: translateY(-5px);  
}

.leftFooter h5 {
  font-family:  "Playfair Display", serif;
  text-decoration: underline;
  color: inherit;
  margin-left: 40px;
}

.leftFooter p {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  margin: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
  font-weight: 550;
  gap: 10px;
}

.leftFooter a {
  text-decoration: none;
  color: inherit;
  margin-right: 10px;
  font-size: 20px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.leftFooter a:hover {
  color: #A1A1E1;
  transform: scale(1.05);
}

.leftFooter .fas {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.leftFooter .fas:hover {
  animation: bounce 0.5s;
}

.leftFooter .fa-phone {
  color: #28a745;
}

.leftFooter .fa-envelope {
  color: #dc3545;
}

.leftFooter .fa-map-marker-alt {
  color: #ffc107;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.midFooter {
  width: 50%;
  text-align: center;
  color: #2c2c2c;
  padding-bottom: 50px;
}

.logoss {
  text-decoration: none; /* Remove underline */
  font-family: 'Montserrat', sans-serif; /* A modern and attractive font */
  font-size: 26px; /* Adjust font size as needed */
  color: #222222; /* Text color */
  text-transform: uppercase; /* Uppercase text for emphasis */
  letter-spacing: 2px; /* Increase letter spacing for a modern look */
  overflow: hidden; /* Hide overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
  display: flex;
  align-items: center;
  gap:20px;
  font-weight: 700;
  margin-left: 160px;
  margin-top: 50px;
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transition */
}

.logoss:hover {
  color: #555; /* Text color on hover */
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Subtle glow effect */
  }

.footer-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 15px 0;
  background-color: whitesmoke;
  margin-bottom: 5px;
  margin-right: 30px;
  margin-top: 5px;
  margin-left: 50px;
}

.footer-links a {
  font-family:  "Playfair Display",serif;
  text-decoration: none; 
  color: #333;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease, text-decoration 0.3s ease;
  display: flex; /* To align icon and text horizontally */
  align-items: center; /* Center-align icon and text */
}

.footer-links a i {
  margin-right: 8px; /* Space between icon and text */
}

.footer-links a:hover {
  background-color: #A1A1E1;
  color: #fff;
}

.footer-links a:hover i {
  color: #fff; /* Ensures icon color matches text color on hover */
}

.svgs{
  margin-right: 140px;
}

.svgs svg {
  margin: 0 2px;
}

.copyrights {
  text-align: center;
  padding: 12px;
  margin-right: 138px;
}

.copyrights h6 {
  font: 550 1.3vmax  "Playfair Display", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 2px 5px; /* Added padding for better text spacing */
  transition: background-color 0.3s ease, color 0.3s ease; 
  cursor: pointer;
  display: inline-block; /* Make sure the background color only covers the text */
}

.copyrights h6:hover {
  background-color: #A1A1E1; /* Blue background on hover */
  color: #fff; /* White text on hover */
  padding: 5px 6px; /* Ensure padding remains consistent on hover */
}

.footer-social-icon {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2c2c2c;
  font: 500 1.5vmax "Playfair Display", sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 1s ease-out;
  padding-bottom: 70px;
  margin-right: 40px;
  margin-top: 80px;
}

.footer-social-icon:hover {
  transform: translateY(-5px);
}

.footer-social-icon h5 {
  font-family: "Playfair Display", serif;
  color: #212529;
  text-decoration: underline;
  margin-bottom: 16px;
  font-size: 24px;
}

.footer-social-icon a {
  font-family:  "Playfair Display",serif;
  text-decoration: none;
  display: flex;
  margin: 0.5vmax;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, color 0.3s ease, font-size 0.3s ease, background-color 0.3s ease;
  font-size: 25px; /* Default icon size */
  gap: 7px;
  background-color: whitesmoke; /* Matches the footer background color */
  padding: 5px; /* Adjust padding as needed */
}

.footer-social-icon a:hover {
  background-color: whitesmoke; /* Keeps the same background color on hover */
  transform: scale(1.05); /* Slightly increase the size */
}

.footer-social-icon a svg {
  transition: transform 0.3s ease;
  color: #2c2c2c; /* Ensure the icon color is consistent */
}

.footer-social-icon a:hover svg {
  transform: rotate(20deg); /* Add a slight rotation effect on hover */
  color: #2c2c2c; /* Ensure the icon color remains consistent on hover */
}

/* Mobile Responsive Styling */
@media screen and (max-width: 600px) {
  
  footer {
    height: 1180px; /* Adjust height to fit content */
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;
    width: 110vw; /* Full viewport width */
    box-sizing: border-box; /* Include padding in width */
  }

  /* Logo section at the top */
  .logoss {
    order: 1; /* First item */
    font-size: 22px;
    font-weight: bold;
    margin: 10px 0; /* Space between logo and next section */
    margin-top: 25px;
    text-align: center;
    letter-spacing: 1px;
    margin-left: 12px;
  }

  /* Follow Us section after logo */
  .footer-social-icon {
    order: 2; /* Second item */
    width: 120%; /* Full width */
    display: flex;
    gap: 8px;
    margin-bottom: -55px; /* Space between social icons and next section */
    margin-top: -20px;
    margin-right: 210px;
  }

  .footer-social-icon a {
    font-size: 20px;
    padding: 10px;
    margin-left: 60px;
  }

  /* Footer links section after Follow Us */
  .footer-links {
    order: 3; /* Third item */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: 11px;
    width: 130%; /* Full width */
    margin-left: -70px; /* Align links to the left */
  }

  .footer-links a {
    font-size: 16px;
    padding: 5px;
    width: 90%; /* Slight margin for better spacing */
  }

  /* Contact Information after footer links */
  .leftFooter {
    order: 4; /* Fourth item */
    padding: 10px;
    margin-top: 15px;
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding in width */
    text-align: left; /* Align contact information to the left */
    margin-bottom: 15px;
  }

  .leftFooter h5 {
    font-size: 19px;
    margin-bottom: 10px;
    font-family: "Playfair Display",serif;
  }

  .leftFooter p, .leftFooter a {
    font-size: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center; /* Align icon and text */
  }

  .leftFooter a {
    word-wrap: break-word; /* Break long URLs */
  }

  .leftFooter .fas {
    font-size: 18px;
    margin-right: 10px; /* Space between icon and text */
  }

  /* SVGs section next */
  .svgs {
    order: 5; /* Fifth item */
    margin-top: 20px;
    width: 140%; /* Full width */
    text-align: center;
    margin-left: -60px;
  }

  .svgs svg {
    margin: 0 2px;
  }

  /* Copyright section at the bottom */
  .copyrights {
    order: 6; /* Last item */
    padding: 15px;
    margin-top: 10px;
    font-size: 14px;
    width: 160%; /* Full width */
    text-align: center;
    margin-left: -77px;
  }

  .copyrights h6 {
    font-size: 13px;
  }
}
